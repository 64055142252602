// Here you can add other styles
@import './variables';

.breadcrumb-item {
  & > a {
    color           : $theme-font-base;
    text-decoration : none;
  }
}

.sidebar-nav .nav-link {
  mix-blend-mode : hard-light;
}


@keyframes ppsFadeIn {
  from { opacity : 0 }
  to { opacity : 1 }
}

.pps-fade-in {
  animation : .5s ppsFadeIn ease-in-out;
}
